<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false" :editar="false" :eliminar="false"></nav-form>
    <section>
      <article style="height: 395px; width: 1200px">
        <img
          :src="
            formData.imagen && typeof formData.imagen == 'object'
              ? formData.imagen.imagen
              : require('./../assets/noimage.png')
          "
          style="
            position: absolute;
            width: 40px;
            height: 40px;
            top: 4px;
            left: 6px;
          "
        />
        <field
          name="codigo"
          widget="char"
          searchable
          label="Código"
          help="Código del proveedor"
          placeholder="Cód. proveedor"
          width="100px"
          style="top: 5px; left: 68px"
          :readonly="mode == 'edit'"
          inputStyle="font-weight:bold;text-align:center;"
        />
        <field
          name="nombre_comercial"
          widget="char"
          searchable
          label="Nombre comercial"
          help="Nombre comercial del proveedor"
          required
          placeholder="Nombre comercial del proveedor"
          width="300px"
          style="top: 5px; left: 180px"
        />

        <r-tabs
          ref="main-tabs"
          style="top: 40px; height: 355px"
          :wt="wt"
          :buttons="[
            'Movimientos',
            'Pedidos',
            'Albaranes',
            'Facturas',
            'Vencimientos',
          ]"
        >
          <!-- Movimientos -->
          <div class="tab">
            <field
              name="movimientos"
              structureRef="consulta_articulos_por_proveedor.movimientos"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                { name: 'fecha', type: 'date2', header: 'Fecha' },
                {
                  name: 'tipo',
                  header: 'Tipo',
                  type: 'select',
                  options: {
                    factura_proveedor: 'FV - Factura de proveedor',
                    albaran_proveedor: 'AV - Albaran de proveedor',
                    condicional_proveedor: 'CV - Condicional de proveedor',
                    pedido_proveedor: 'PV - Pedido de proveedor',
                    factura_proveedor: 'FC - Factura de Proveedor',
                    albaran_proveedor: 'AC - Albaran de Proveedor',
                    condicional_proveedor: 'CC - Condicional de Proveedor',
                    pedido_proveedor: 'PC - Pedido de Proveedor',
                    deposito_representante: 'DR - Deposito de Representante',
                    regularizacion: 'RG - Regularización',
                  },
                },
                { name: 'ndoc', header: 'Doc.' },
                {
                  name: 'origen_id',
                  header: 'Origen',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                {
                  name: 'destino_id',
                  header: 'Destino',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                },
                /*{
                  name: 'totallinea',
                  header: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                }*/
              ]"
            />
          </div>
          <!-- Pedidos -->
          <div class="tab">
            <field
              name="pedidos"
              structureRef="consulta_articulos_por_proveedor.pedidos"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                { name: 'fecha', type: 'date2', header: 'Fecha' },
                { name: 'ndoc', header: 'Doc.' },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                },
                /*{
                  name: 'totallinea',
                  header: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                }*/
              ]"
            />
          </div>
          <!-- Albaranes -->
          <div class="tab">
            <field
              name="albaranes"
              structureRef="consulta_articulos_por_proveedor.albaranes"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                { name: 'fecha', type: 'date2', header: 'Fecha' },
                {
                  name: 'tipo',
                  header: 'Tipo',
                  type: 'select',
                  options: {
                    albaran_proveedor: 'A - Albaran',
                    condicional_proveedor: 'C - Condicional',
                  },
                },
                { name: 'ndoc', header: 'Doc.' },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                },
                /*{
                  name: 'totallinea',
                  header: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                }*/
              ]"
            />
          </div>
          <!-- Facturas -->
          <div class="tab">
            <field
              name="facturas"
              structureRef="consulta_articulos_por_proveedor.facturas"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                { name: 'fecha', type: 'date2', header: 'Fecha' },
                { name: 'ndoc', header: 'Doc.' },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                },
                /*{
                  name: 'totallinea',
                  header: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                }*/
              ]"
            />
          </div>
          <!-- Vencimientos -->
          <div class="tab">
            <field
              name="vencimientos"
              structureRef="consulta_articulos_por_proveedor.vencimientos"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                {
                  name: 'factura_id.formapago_id',
                  header: 'F.pago',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'descripcion',
                  showCode: true,
                },
                { name: 'fecha', type: 'date2', header: 'Fecha' },
                { name: 'factura_id.ndoc', header: 'Doc.' },
                {
                  name: 'importe',
                  header: 'Importe',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'factura_id.divisa_id',
                  header: 'Divisa',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                },
              ]"
            />
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Consulta de artículos por proveedor",
      dbAdapter: "proveedor",
      primary: "codigo",
      fields: [{ name: "imagen", fields: ["imagen"] }],
      wt: 0,
      movimientos: [],
      vencimientos: [],
    };
  },
  methods: {
    actualizarMovimientos() {
      var self = this;
      return window.DB.action("articulo", "getMovimientos", {
        data: {
          proveedor_id: self.itemId,
        },
      }).then(function (res) {
        self.movimientos = res.data;
      });
    },
    actualizarVencimientos() {
      var self = this;
      return window.DB.getList("vencimiento_proveedor", {
        depth: 2,
        fields: [
          "fecha",
          "importe",
          {
            name: "factura_id",
            fields: [
              "ndoc",
              { name: "formapago_id", fields: ["codigo", "descripcion"] },
              { name: "divisa_id", fields: ["codigo", "nombre"] },
            ],
          },
        ],
        filter: ["factura_id.proveedor_id", "=", self.itemId],
        order: "fecha",
        orderDir: "DESC",
      }).then(function (res) {
        self.vencimientos = res.data; //.map(x => {...x, {formapago_id: x.factura_id.formapago_id}});
      });
    },
    mostrarTablas() {
      var self = this;
      let movs = self.$utils.misc.clone(self.movimientos);
      let m = movs.filter((x) =>
        self.wt == 1
          ? parseInt(x.wt || 0)
          : self.wt == 0
          ? !parseInt(x.wt || 0)
          : 1
      );
      self.$set(self.formData, "movimientos", self.$utils.misc.clone(m));
      let pc = self.$utils.misc
        .clone(m)
        .filter((x) => x.tipo == "pedido_proveedor");
      self.$set(self.formData, "pedidos", pc);
      let cc = self.$utils.misc
        .clone(m)
        .filter(
          (x) => x.tipo == "condicional_proveedor" || x.tipo == "albaran_proveedor"
        );
      self.$set(self.formData, "albaranes", cc);
      let fc = self.$utils.misc
        .clone(m)
        .filter((x) => x.tipo == "factura_proveedor");
      self.$set(self.formData, "facturas", fc);

      let vv = self.$utils.misc.clone(self.vencimientos);
      self.$set(self.formData, "vencimientos", vv);
    },
  },
  mounted() {
    var self = this;

    self.$on("loadItem", function () {
      Promise.allSettled([
        self.actualizarMovimientos(),
        self.actualizarVencimientos(),
      ]).then((_) => ((self.loading = false), self.mostrarTablas()));
    });

    self.$refs["main-tabs"].$refs.toolbar.addEventListener(
      "dblclick",
      function (e) {
        if (self.app.session.mode == "a") return;
        if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
          self.wt = self.wt == 1 ? 2 : self.wt == 2 ? 0 : 1;
          self.mostrarTablas();
        }
      }
    );
  },
};
</script>